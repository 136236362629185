import olgaNominada from '../../discos/olga-nominada.png'
import mujerEsperanza from '../../discos/mujerEsperanza1000x1000bb.webp'
import mxf from '../../discos/mxfBig.jpeg'
import muva from '../../discos/muva.jpg'
import chimalaFeliz from '../../discos/chimbalaFeliz.png'
import zemmoaSigueme from '../../discos/zemmoaSigueme.png'
import tydDerretida from '../../discos/thayanafriaDerretida.png'

import mariachiQueSea from '../../discos/mariachi-queSea.png'
import zemmoaCorazon from '../../discos/zemmoa-corazon.png'
import remikGonzalezUnBaiza from '../../discos/RemikGonzalez-UnBaiza.png'
import grtschPokito from '../../discos/grtsch-pokito.png'
import granSurNoche from '../../discos/granSur-noche.png'
import cartelMarihuano from '../../discos/cartel-marihuano.png'
import brianVolante from '../../discos/brian-volante.png'
import chptr from '../../discos/IMG_0214.jpg'
import espacio from '../../discos/IMG_0215.jpg'

import despiertas from '../../discos/IMG_9512.jpg'
import majin from '../../discos/IMG_9513.jpg'
import sacaeltoque from '../../discos/IMG_9514.jpg'

import hojadete from '../../discos/IMG_9516.jpg'
import holidays from '../../discos/IMG_9517.jpg'
import notenia from '../../discos/IMG_9518.jpg'
import quisiera from '../../discos/IMG_9519.jpg'
import besar from '../../discos/IMG_9520.jpg'
import psycho from '../../discos/IMG_9521.jpg'
import polvo from '../../discos/IMG_9522.jpg'

import enLasLucesDeLaTarde from '../../discos/enLasLucesDeLaTarde.jpeg'
import teMerecesUnAmor from '../../discos/teMerecesUnAmor.jpeg'
import laCasitaDeLaSelva from '../../discos/laCasitaDeLaSelva.jpeg'
// import besar from '../../discos/Besar.jpeg'
import teExtrano from '../../discos/teExtrano.jpeg'
import fth from '../../discos/agosto/FTH-FuFuMJay.jpeg'
import goat from '../../discos/agosto/Goat-Aczino.jpeg'
import hiloRojo from '../../discos/septiembre/GRTSCH-HiloRojo.jpeg'
import conmigo from '../../discos/septiembre/Conmigo.jpeg'
import paQueLoSuene from '../../discos/septiembre/MaoSky-paqueLoSuene.jpeg'
import prohibidoLlorar from '../../discos/septiembre/VIVIRQUINTANA-sevaleLlorar.jpeg'
import corazonDeAve from '../../discos/septiembre/VIVIRQUINTANA-CorazonDeAve.jpeg'

import caribe from '../../discos/noviembre/leonneon-caribe.jpg'
import pasaras from '../../discos/noviembre/vivirQuintana-pasaras.jpg'
import mexicoEnMiVoz from '../../discos/noviembre/christianNodal-mexicoEnMiVoz.jpg'

import tuCamisa from '../../discos/noviembre/escarlataShantty-tuCamisa.jpg'
import sabor from '../../discos/noviembre/maoSky-sabor.jpg'
import alPueblo from '../../discos/febrero2024/alPueblo.jpeg'
import masAlto from '../../discos/febrero2024/masAlto.jpeg'

import luaLacruz from '../../discos/julio2024/LUA_LACRUZ_EN_CUALQUIER_MOMENTO.png'

import romiGenero from '../../discos/julio2024/ROMI_AXHE_SAFREE_GENERO_REMIX.png'
import snowApple from '../../discos/julio2024/SNOW_APPLE_LA_CUMBIA_DE_LAS_LOCAS.png'
import sofiMata from '../../discos/julio2024/SOFI_MATA_SI_ME_DICES_QUE_SI.png'
import zemmoaMio from '../../discos/julio2024/ZEMMOA_MIO_GLADIATORE.png'
import zemmoaQueriendonos from '../../discos/julio2024/ZEMMOA_QUERIENDONOS_BIEN.png'
import zemmoaCancion from '../../discos/julio2024/ZEMMOA-MI_FAVORITA_CANCION.png'

import ovejaNegra from '../../discos/sep2024/ovejaNegra.png'
import foudequshMamixhula from '../../discos/sep2024/foudequshMamixhula.png'

import gretschVolver from '../../discos/sep2024/gretschVolver.png'

import cumbiaClandestina from '../../discos/sep2024/cumbiaClandestina.png'

import corridosPaMisCompas from '../../discos/sep2024/corridosPaMisCompas.png'

import s925 from '../../discos/sep2024/s925.png'

const data = [
  {
    single: 'Oveja Negra',
    nombre: '',
    artista: 'GhettoLiving',
    link: 'https://music.apple.com/mx/album/oveja-negra/1704247766',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'Universal Music Group México (UMM)',
    id: 'record0064',
    portada: ovejaNegra
  },
  {
    single: 'Mercedes Blanco, Mamixhula',
    nombre: 'Apple Music Home Session',
    artista: 'GhettoLiving',
    link: 'https://music.apple.com/mx/album/apple-music-home-session-foudeqush-single/1666051342',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'Finess Records',
    id: 'record0063',
    portada: foudequshMamixhula
  },

  {
    single: 'Volver',
    nombre: '',
    artista: 'Gretsch',
    link: 'https://music.apple.com/mx/album/volver-single/1757531516',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'Universal Music Group México (UMM)',
    id: 'record0062',
    portada: gretschVolver
  },

  {
    single: 'Cumbia Clandestina - EP',
    nombre: '',
    artista: 'Mao Skaay & Thcumbia',
    link: 'https://music.apple.com/mx/album/cumbia-clandestina-ep/1729907606',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'Masare Records',
    id: 'record0061',
    portada: cumbiaClandestina
  },

  {
    single: 'Corridos pa mis compas y rolitas pa mi ex',
    nombre: '',
    artista: 'La Receta',
    link: 'https://music.apple.com/mx/album/corridos-pa-mis-compas-y-rolitas-pa-mi-ex/1761664153',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'DALE PLAY Records / Solido Records',
    id: 'record0060',
    portada: corridosPaMisCompas
  },

  {
    single: 'S925',
    nombre: '',
    artista: 'Plata Shail',
    link: 'https://music.apple.com/mx/album/s925/1712579604 ',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'Universal Music Group México (UMM)',
    id: 'record0059',
    portada: s925
  },

  {
    single: 'Género (Remix)',
    nombre: '',
    artista: 'ROMI MARCOS - AXHE - SAFREE',
    link: 'https://music.apple.com/mx/album/g%C3%A9nero-remix/1732153492?i=1732153497&l=en-GB',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'OneRpm',
    id: 'record0058',
    portada: romiGenero
  },

  {
    single: 'La cumbia de las locas',
    nombre: '',
    artista: 'Snow Apple',
    link: 'https://music.apple.com/mx/album/cumbia-de-las-locas/1738962166?i=1738962289&l=en-GB',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'Snowapple BV',
    id: 'record0057',
    portada: snowApple
  },
  {
    single: 'Si me dices que sí',
    nombre: '',
    artista: 'Sofi Mata',
    link: 'https://music.apple.com/mx/album/si-me-dices-que-s%C3%AD/1735852428?i=1735852429&l=en-GB',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'UMG',
    id: 'record0056',
    portada: sofiMata
  },

  {
    single: 'En cualquier momento',
    nombre: '',
    artista: 'Lua Lacruz',
    link: 'https://music.apple.com/mx/album/en-cualquier-momento/1733898132?i=1733898382&l=en-GB',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'UMG',
    id: 'record0054',
    portada: luaLacruz
  },

  {
    single: 'Mi favorita canción',
    nombre: '',
    artista: 'Zemmoa',
    link: 'https://music.apple.com/mx/album/mi-favorita-canci%C3%B3n-single/1755429427?l=en-GB',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'UMG',
    id: 'record0052',
    portada: zemmoaCancion
  },
  {
    single: 'Queriéndonos bien',
    nombre: '',
    artista: 'Zemmoa',
    link: 'https://music.apple.com/mx/album/queri%C3%A9ndonos-bien/1740423962?i=1740424222&l=en-GB',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'UMG',
    id: 'record0051',
    portada: zemmoaQueriendonos
  },
  {
    single: 'Mio Gladiatore',
    nombre: '',
    artista: 'Zemmoa',
    link: 'https://music.apple.com/mx/album/mio-gladiatore/1732364756?i=1732365108&l=en-GB',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'UMG',
    id: 'record0050',
    portada: zemmoaMio
  },

  {
    single: 'Vuela más alto',
    nombre: '',
    artista: 'Zemmoa',
    link: ' https://music.apple.com/sv/album/vuela-m%C3%A1s-alto/1728764783?i=1728764784&l=en-GB ',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'UMG',
    id: 'record0048',
    portada: masAlto
  },

  {
    single: '¿Quién cuida al pueblo?',
    nombre: '',
    artista: 'Vivir Quintana , Andrea Echeverri, La Marisol',
    link: 'https://music.apple.com/mx/album/qui%C3%A9n-cuida-al-pueblo/1726678430?i=1726678431',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'UMG',
    id: 'record0046',
    portada: alPueblo
  },
  {
    single: 'Sabor',
    nombre: '',
    artista: 'Mao Skaay X Thcumbia',
    link: 'https://music.apple.com/mx/album/sabor-single/1704328638',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'Masare Records',
    id: 'record0045',
    portada: sabor
  },
  {
    single: 'Tu Camisa',
    nombre: '',
    artista: 'Escarlata & Shantty',
    link: 'https://music.apple.com/mx/album/tu-camisa/1710238232?i=1710238236',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'Warner Music',
    id: 'record0044',
    portada: tuCamisa
  },

  {
    single: 'Mujeres Divinas',
    nombre: '',
    artista: 'Christian Nodal',
    link: 'https://music.apple.com/sv/album/mujeres-divinas/1706383410?i=1706383424&l=en-GB',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'Sony Music Entertainment',
    id: 'record0043',
    portada: mexicoEnMiVoz
  },
  {
    single: 'La Ley del Monte',
    nombre: '',
    artista: 'Christian Nodal',
    link: 'https://music.apple.com/sv/album/la-ley-del-monte/1706383410?i=1706383552&l=en-GB',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'Sony Music Entertainment',
    id: 'record0042',
    portada: mexicoEnMiVoz
  },
  {
    single: 'Pasarás',
    nombre: '',
    artista: 'Vivir Quintana',
    link: 'https://music.apple.com/mx/album/pasar%C3%A1s-single/1705158593',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'EMI; Universal Music Group México, S.A. de C.V.',
    id: 'record0041',
    portada: pasaras
  },
  {
    single: 'Caribe',
    nombre: '',
    artista: 'Leon Neon',
    link: 'https://music.apple.com/mx/album/caribe/1708337559?i=1708337912',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: '',
    id: 'record0040',
    portada: caribe
  },
  {
    single: 'Corazón de Ave',
    nombre: '',
    artista: 'Vivir Quintana',
    link: 'https://music.apple.com/mx/album/coraz%C3%B3n-de-ave/1691433287?i=1691433300',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'EMI; Universal Music Group México, S.A. de C.V.',
    id: 'record0039',
    portada: corazonDeAve
  },
  {
    single: 'Proibido Llorar',
    nombre: '',
    artista: 'Vivir Quintana',
    link: 'https://music.apple.com/mx/album/prohibido-llorar/1696330621?i=1696330624',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'EMI; Universal Music Group México, S.A. de C.V.',
    id: 'record0038',
    portada: prohibidoLlorar
  },
  {
    single: 'Pa Que Lo Suene',
    nombre: '',
    artista: 'Mao Skaay feat.Neto Reyno',
    link: 'https://music.apple.com/mx/album/pa-que-lo-suene-feat-neto-reyno/1696649561?i=1696649562',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: '2023 Masare Records',
    id: 'record0037',
    portada: paQueLoSuene
  },
  {
    single: 'Conmigo',
    nombre: '',
    artista: 'Zöelle',
    link: 'https://music.apple.com/mx/album/conmigo/1698097998?i=1698098008',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: '2023 pink nebula records',
    id: 'record0036',
    portada: conmigo
  },
  {
    single: 'Hilo Rojo',
    nombre: '',
    artista: 'GRTSCH',
    link: 'https://music.apple.com/sv/album/hilo-rojo/1700671146?i=1700671307&l=en-GB',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    extra: 'Mix Estéreo',
    soundtech: '2023 GRTSCH',
    id: 'record0035',
    portada: hiloRojo
  },
  {
    single: 'FTH',
    nombre: '',
    artista: 'Emjay, FUFU',
    link: 'https://music.apple.com/mx/album/fth/1696276573?i=1696276794',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'Warner Music México, S.A. de C.V. / GORGONA RECORDS',
    id: 'record0034',
    portada: fth
  },
  {
    single: 'El G.O.A.T.',
    nombre: '',
    artista: 'Aczino',
    link: 'https://music.apple.com/mx/album/championships/1693711055?i=1693711398',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'EMI; Universal Music Group México, S.A. de C.V.',
    id: 'record0033',
    portada: goat
  },
  {
    single: 'En Las Luces De La Tarde',
    nombre: '',
    artista: 'Vivir Quintana',
    link: 'https://music.apple.com/mx/album/en-las-luces-de-la-tarde-single/1669557850',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'Universal Music Group México, S.A. de C.V.',
    id: 'record0028',
    portada: enLasLucesDeLaTarde
  },
  {
    single: 'Te Mereces Un Amor',
    nombre: '',
    artista: 'Vivir Quintana',
    link: 'https://music.apple.com/mx/album/te-mereces-un-amor-single/1672150258',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'Universal Music Group México, S.A. de C.V.',
    id: 'record0029',
    portada: teMerecesUnAmor
  },
  {
    single: 'La Casita De La Selva',
    nombre: '',
    artista: 'Vivir Quintana',
    link: 'https://music.apple.com/mx/album/la-casita-de-la-selva-single/1676373549',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'Universal Music Group México, S.A. de C.V.',
    id: 'record0030',
    portada: laCasitaDeLaSelva
  },
  {
    single: 'Besar',
    nombre: '',
    artista: 'Vivir Quintana',
    link: 'https://music.apple.com/mx/album/besar-single/1684870891',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'Universal Music Group México, S.A. de C.V.',
    id: 'record0031',
    portada: besar
  },
  {
    single: 'Te Extraño',
    nombre: '',
    artista: 'Gran Sur & Natalia Jiménez',
    link: 'https://music.apple.com/mx/album/te-extra%C3%B1o-single/1666644242',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'Bunker Producciones, S.A. de C.V.',
    id: 'record0032',
    portada: teExtrano
  },
  {
    single: 'Los del Espacio',
    nombre: '',
    artista: 'Los del Espacio',
    link: 'https://music.apple.com/mx/album/los-del-espacio/1687418530?i=1687418898',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'Warner Music',
    id: 'record0026',
    portada: espacio
  },
  {
    single: 'POLVO (feat. Denise Gutiérrez)',
    nombre: '',
    artista: 'GRTSCH',
    link: 'https://music.apple.com/mx/album/polvo-feat-denise-gutiérrez/1681129062?i=1681129280',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: '',
    id: 'record0025',
    portada: polvo
  },

  // {
  //   single: 'Besar',
  //   nombre: '',
  //   artista: 'Vivir Quintana',
  //   link: 'https://music.apple.com/mx/album/besar/1684870891?i=1684870895',
  //   plataforma: 'AppleMusic',
  //   logos: ['dolby'],
  //   soundtech: 'Universal Music Group México, S.A. de C.V.',
  //   id: 'record0023',
  //   portada: besar
  // },
  {
    single: 'Quisiera Retroceder',
    nombre: 'Remik Gonzalez',
    artista: 'Remik Gonzalez & Homer el Mero Mero',
    link: 'https://music.apple.com/mx/album/quisiera-retroceder/1644428554?i=1644428555',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: '',
    id: 'record0022',
    portada: quisiera
  },
  {
    single: 'No Tenía Nada (feat. Kodigo)',
    nombre: 'Remik Gonzalez',
    artista: 'Remik Gonzalez',
    link: 'https://music.apple.com/mx/album/no-ten%C3%ADa-nada-feat-kodigo-single/1666646575',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: '',
    id: 'record0021',
    portada: notenia
  },
  {
    single: '',
    nombre: 'Holidays',
    artista: 'Kalimba & Fela Dominguez',
    link: 'https://music.apple.com/mx/album/holidays/1654996117',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'Universal Music Group México, S.A. de C.V. ',
    id: 'record0020',
    portada: holidays
  },
  {
    single: 'Hojadeté',
    nombre: 'GRTSCH',
    artista: 'GRTSCH',
    link: 'https://music.apple.com/mx/album/hojadeté/1656779699?i=1656779702',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    extra: 'Mix Estéreo',
    soundtech: '',
    id: 'record0019',
    portada: hojadete
  },
  {
    single: 'Psychob!Tch',
    nombre: '',
    artista: 'RAPHAËLLA',
    link: 'https://music.apple.com/mx/album/psychob-tch/1681117516?i=1681117521',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'GOROGORO IL',
    id: 'record0024',
    portada: psycho
  },

  {
    single: 'Saca el toke',
    // nombre: 'Masare Records ',
    artista: 'Chikis RA, Ali Masare & Mao Skaay',
    link: 'https://music.apple.com/mx/album/saca-el-toke/1651006771?i=1651006772',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'Masare Records',
    id: 'record0017',
    portada: sacaeltoque
  },

  {
    single: 'Despiertas',
    nombre: '',
    artista: 'Ms. Ambar, Delfina Dib & Masta Quba',
    link: 'https://music.apple.com/mx/album/despiertas/1668848909?i=1668848911',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'ONErpm',
    id: 'record0015',
    portada: despiertas
  },
  {
    single: 'El Jala Jala',
    nombre: "Pal' Combo Es Lo Que Hay",
    artista: 'Olga Tañón',
    premio: 'Latin Grammy Nominee(2022), Best Merengue/Bachata Album',
    link: 'https://music.apple.com/sv/album/el-jala-jala/1736961709?i=1736961716&l=en-GB',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'ONErpm',
    id: 'record001',
    portada: olgaNominada
  },
  {
    nombre: 'FELIZ',
    artista: 'Chimbala',
    link: 'https://music.apple.com/mx/album/feliz/1624536261?i=1624536262',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'ONErpm',
    id: 'record002',
    portada: chimalaFeliz
  },
  {
    single: 'Sígueme ',
    nombre: '',
    artista: 'Zemmoa',
    link: 'https://music.apple.com/mx/album/s%C3%ADgueme/1629523438?i=1629523749',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'Alafonte',
    id: 'record003',
    portada: zemmoaSigueme
  },
  {
    single: 'Querido Corazón ',
    nombre: '',
    artista: 'Zemmoa',
    link: 'https://music.apple.com/mx/album/querido-coraz%C3%B3n-single/1623445603',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'Alafonte',
    id: 'record004',
    portada: zemmoaCorazon
  },

  {
    single: 'Fría y Derretida',
    nombre: '',
    artista: 'Tayhana',
    link: 'https://music.apple.com/mx/album/fr%C3%ADa-y-derretida/1630415786?i=1630415788',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'N.A.A.F.I',
    id: 'record005',
    portada: tydDerretida
  },
  {
    single: 'La Noche Es Cabrona ',
    nombre: 'El Otro Lado',
    artista: 'Gran Sur',
    mezcla: '',
    link: 'https://music.apple.com/mx/album/la-noche-es-cabrona/1639148139?i=1639148145',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'Alafonte',
    id: 'record006',
    portada: granSurNoche
  },
  {
    single: 'Majin',
    nombre: '',
    artista: 'RAPHAËLLA',
    link: 'https://music.apple.com/mx/album/majin/1676678428?i=1676678435',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'GOROGORO IL',
    id: 'record0016',
    portada: majin
  },
  {
    single: 'Rasgar',
    nombre: '',
    artista: 'MexFutura',
    produccion: 'Rasgar + Entre Restos',
    mezcla: 'Mezclas con sonido inmersivo (Ambisonic)',
    link: 'https://youtu.be/73Ic501fa6A',
    plataforma: 'YouTube',
    logos: ['dear'],
    soundtech: true,
    id: 'record007',
    portada: mxf
  },
  {
    single: '',
    nombre: 'O Fortuna',
    artista: 'Muva',
    mezcla: 'Mezclas con sonido inmersivo (Ambisonic)',
    link: 'https://youtu.be/9joBQj2BSGg',
    plataforma: 'YouTube',
    logos: ['dear'],
    soundtech: true,
    id: 'record008',
    portada: muva
  },

  {
    single: '',
    nombre: 'Mujer Esperanza',
    artista: 'Luz María Carriquiry',
    link: 'https://music.apple.com/mx/album/mujer-esperanza-feat-elizabeth-morris-liuba-mar%C3%ADa-hevia/1725340262?i=1725340263&l=en-GB',
    plataforma: 'AppleMusic',
    logos: ['lossless', 'tidal', 'spotify', 'amazon'],
    soundtech: true,
    id: 'record009',
    portada: mujerEsperanza
  },

  {
    single: 'Yo Voy Al Volante ',
    nombre: '',
    artista: 'Brian Sandoval',
    link: 'https://music.apple.com/mx/album/yo-voy-al-volante/1641502741?i=1641502742',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'ONErpm',
    id: 'record0010',
    portada: brianVolante
  },
  {
    single: '',
    nombre: 'Viejo Marihuano',
    artista: 'Cartel de Santa',
    link: 'https://music.apple.com/mx/album/viejo-marihuano/1541937039',
    plataforma: 'AppleMusic',
    logos: [],
    soundtech: 'Sony Music',
    id: 'record0011',
    portada: cartelMarihuano
  },
  {
    single: '',
    nombre: 'Un Baiza',
    artista: 'Remik González',
    link: 'https://music.apple.com/mx/album/un-baiza/1642593042',
    plataforma: 'AppleMusic',
    logos: [],
    soundtech: 'ONErpm',
    id: 'record0012',
    portada: remikGonzalezUnBaiza
  },
  {
    single: 'Pokito',
    nombre: '',
    artista: 'Grtsch',
    link: 'https://music.apple.com/mx/album/pokito/1643985230?i=1643985243',
    plataforma: 'AppleMusic',
    logos: [],
    soundtech: 'Alafonte',
    id: 'record0013',
    portada: grtschPokito
  },
  {
    single: '3CHPTR SONG',
    nombre: '',
    artista: 'RAPHAËLA',
    link: 'https://music.apple.com/mx/album/3-chptr-song/1689457272?i=1689457274',
    plataforma: 'AppleMusic',
    logos: ['dolby'],
    soundtech: 'GOROGORO IL',
    id: 'record0027',
    portada: chptr
  },
  {
    single: 'Que Sea',
    nombre: '',
    artista: 'Mariachi Internacional CHG',
    link: 'https://music.apple.com/mx/album/que-sea-cover/1639381132?i=1639381133',
    plataforma: 'AppleMusic',
    logos: [],
    soundtech: 'ONErpm',
    id: 'record0014',
    portada: mariachiQueSea
  }
]

// const shuffledData = array => {
//   for (let i = array.length - 1; i > 0; i--) {
//     const j = Math.floor(Math.random() * (i + 1))
//     ;[array[i], array[j]] = [array[j], array[i]]
//   }
//   return array
// }

function shuffleWithoutConsecutiveArtists(arr) {
  let shuffled = [...arr]
  shuffled.sort(() => Math.random() - 0.5)

  for (let i = 1; i < shuffled.length; i++) {
    if (shuffled[i].artista === shuffled[i - 1].artista) {
      if (i + 1 < shuffled.length) {
        ;[shuffled[i], shuffled[i + 1]] = [shuffled[i + 1], shuffled[i]]
      } else {
        shuffled = shuffleWithoutConsecutiveArtists(arr)
        break
      }
    }
  }

  return shuffled
}

const finalData = shuffleWithoutConsecutiveArtists(data)

export default finalData
